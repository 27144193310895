.modal-mask-dark {
    background: rgba(#353535, 0.3);
    backdrop-filter: blur(10px) !important;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 80;
}
.modal-mask-light {
    background: rgba(#fff, 0.3);
    backdrop-filter: blur(10px) !important;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 80;
}
.modal-container-dark {
    .modal-content {
        background-color: #1d1d1d !important;
        border: 1px solid #7dba28;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 592px;
        height: 417px;
        border-radius: 20px;
        text-align: center;
        @media (max-width: 450px) {
            width: 370px !important;
            height: 300px;
        }
        h6 {
            font-size: 36px;
            font-weight: 700;
            margin-top: 124px;
            padding-bottom: 28px;
            color: #fff;
            @media (max-width: 450px) {
                font-size: 23px;
                margin-top: 80px;
            }
        }
        p {
            font-size: 24px;
            color: #fff;
            @media (max-width: 450px) {
                font-size: 18px;
            }
        }
        .modal-dropwdown {
            background-color: #1d1d1d;
            color: #fff;
            width: 250px;
            padding: 10px 20px;
            border: 1px solid #7dba28;
            border-radius: 20px;
            font-size: 14px;
            cursor: pointer;
            margin-top: 20px;
            position: relative;
            svg {
                font-size: 10px !important;
                position: absolute;
                right: 20px;
                bottom: 15px;
            }
        }
        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #7dba28;
            cursor: pointer;
        }
    }
}
.modal-menu-dark {
    padding: 20px 0 !important;
    background-color: #1d1d1d !important;
    border: 1px solid #fff;
    border-radius: 20px !important;
    font-size: 14px;
    overflow-y: auto;
    cursor: pointer;
    width: 250px;
    height: 250px !important;
    position: fixed !important;
    .menuItem {
        color: #fff !important;
        font-size: 14px !important;
        &:hover {
            background-color: #7dba28 !important;
        }
    }
}

.modal-container-light {
    .modal-content {
        background-color: #ffffff !important;
        border: 1px solid #7dba28;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 592px;
        height: 417px;
        border-radius: 20px;
        text-align: center;
        @media (max-width: 450px) {
            width: 370px !important;
            height: 300px;
        }
        h6 {
            font-size: 36px;
            font-weight: 700;
            margin-top: 124px;
            padding-bottom: 28px;
            color: #1d1d1d;
            @media (max-width: 450px) {
                font-size: 23px;
                margin-top: 80px;
            }
        }
        p {
            font-size: 24px;
            color: #1d1d1d;
            @media (max-width: 450px) {
                font-size: 18px;
            }
        }
        .modal-dropwdown {
            background-color: #ffffff;
            color: #1d1d1d;
            width: 250px;
            padding: 10px 20px;
            border: 1px solid #7dba28;
            border-radius: 20px;
            font-size: 14px;
            cursor: pointer;
            margin-top: 20px;
            position: relative;
            svg {
                font-size: 10px !important;
                position: absolute;
                right: 20px;
                bottom: 15px;
            }
        }
        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #7dba28;
            cursor: pointer;
        }
    }
}
.modal-menu-light {
    padding: 20px 0 !important;
    background-color: #fff !important;
    border: 1px solid #ccd1d2;
    border-radius: 20px !important;
    font-size: 14px;
    overflow-y: auto;
    cursor: pointer;
    width: 250px;
    height: 250px !important;
    position: fixed !important;
    .menuItem {
        color: #1d1d1d !important;
        font-size: 14px !important;
        &:hover {
            background-color: #7dba28 !important;
            color: #fff !important;
        }
    }
}


