.drawerContainer {
    .drawerSearchIcon {
        cursor: pointer;
        color: #667479;
    }
}

.drawer {
    background-color: transparent !important;
    height: 102px !important;   
    text-align: center;
    .searchInput {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .input {
            width: 100%;
            outline: none;
            border: 1px solid #7dba28;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            background-color: #fff;
            caret-color: #1b1b1b;
            color: #1b1b1b;
            font-size: 18px;
        }
        svg {
            color: #fff;
            font-size: 25px;
            cursor: pointer;
            margin-top: 10px;
        }
        .search {
            background-color: #7dba28;
            border-radius: 20px;
            padding: 10px;
            position: relative;
            width: 100px;
            right: 168px;
            top: 5px;
            cursor: pointer;
            border: none;
            a {
                color: #fff;
            }
        }
    }
}
