.centers-container {
    padding: 48px 0;
    .centers-header-responsive {
        display: none;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .center-filter {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;
            width: 84px;
            height: 84px;
            border-radius: 100%;
            cursor: pointer;
            position: relative;
            .centers-filter-icon {
                border-radius: 10px;
                cursor: pointer;
            }
        }
        .centers-title {
            width: 100%;
            height: 93px;
            border-radius: 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: 20px 6px;
            .active {
                background-color: #fff;
                color: #353535;
            }
            h4 {
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;
                position: relative;
                z-index: 2;
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 63px;
                color: #fff;
                transition: 0.8s;
            }
            .centers-bg-title {
                position: absolute;
                background-color: #7dba28;
                left: 0;
                height: 50px;
                width: 100%;
                border-radius: 63px;
                z-index: 1;
            }
        }
    }
    .centers-header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .center-filter {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;
            width: 84px;
            height: 84px;
            border-radius: 100%;
            cursor: pointer;
            position: relative;
            .centers-filter-icon {
                border-radius: 10px;
                cursor: pointer;
            }
        }
        .centers-title {
            width: 600px;
            height: 93px;
            border-radius: 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            position: relative;
            h4 {
                font-size: 32px;
                font-weight: 700;
                line-height: 113px;
                text-transform: uppercase;
                cursor: pointer;
                position: relative;
                z-index: 2;
            }
            .centers-bg-title {
                position: absolute;
                background-color: #7dba28;
                left: 0;
                height: 91px;
                border-radius: 63px;
                z-index: 1;
                transition: 1s;
            }
            .title-1 {
                width: 184px;
                transform: translateX(0px);
            }
            .title-2 {
                width: 218px;
                transform: translateX(217px);
            }
            .title-uz {
                width: 280px;
                transform: translateX(217px);
            }
            .title-ru {
                width: 217px;
                transform: translateX(380px);
            }
            .title-en {
                width: 217px;
                transform: translateX(330px);
            }
            .titlee-uz {
                width: 220px;
                transform: translateX(0px);
            }
            .titlee-ru {
                width: 347px;
                transform: translateX(0px);
            }
            .titlee-en {
                width: 300px;
                transform: translateX(0px);
            }
        }
    }
    .pagination {
        text-align: center;
        margin-top: 50px;
    }
    .download {
        text-align: center;
        font-size: 14px;
        text-decoration: underline;
        margin-top: 30px;
        cursor: pointer !important;
        // padding: 50px;
        z-index: 99;
        position: relative;
        a {
            color: #000;
        }
    }
    .center-filter-inputs {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
    .centers-body {
        margin-top: 50px;
        .card-univer {
            margin-top: 44px;
        }
        .card-centers {
            margin-top: 44px;
        }
    }
}
#dark .centers-container {
    .centers-header {
        .center-filter {
            background-color: #fdfdfd;
            .centers-filter-icon {
                background-color: #fdfdfd;
            }
        }
        .centers-title {
            border: 1px solid #fdfdfd;
            h4 {
                color: #fdfdfd;
            }
            .centers-bg-title {
                background-color: #7dba28;
            }
        }
    }
    .download {
        color: #fdfdfd;
    }
}
#light .centers-container {
    .centers-header {
        .center-filter {
            background-color: #353535;
            .centers-filter-icon {
                background-color: #353535;
                svg {
                    fill: #fff;
                }
            }
        }
        .centers-title {
            border: 1px solid #353535;
            h4 {
                color: #353535;
            }
            .centers-bg-title {
                background-color: #7dba28;
            }
        }
    }
}
@media (max-width: 749px) {
    .centers-container {
        .centers-header {
            display: none;
        }
        .centers-header-responsive {
            display: block;
        }
    }
}
@media (max-width: 374px) {
    .centers-container {
        .centers-header {
            display: none;
        }
        .centers-header-responsive {
            display: block;
            .centers-title {
                h4 {
                    font-size: 14px;
                }
            }
        }
    }
}
