@font-face {
    font-family: "MazzardH";
    src: url("../fonts/MazzardH-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "MazzardH";
    src: url("../fonts/MazzardH-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "MazzardH";
    src: url("../fonts/MazzardH-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "MazzardH";
    src: url("../fonts/MazzardH-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "StretchPro";
    src: url("../fonts/StretchPro.ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}