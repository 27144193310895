.courses-container {
    padding-top: 100px;
    .courses-header {
        h4 {
            font-size: 96px;
            font-weight: 700;
            position: relative;
            z-index: 10;
            text-transform: uppercase;
        }
        p {
            font-size: 36px;
            font-weight: 400;
            max-width: 518px;
            margin-top: 20px;
            position: relative;
            z-index: 10;
        }
        .courses-filter-icon {
            margin-left: 82px;
            display: flex;
            justify-content: center;
            padding: 7px 10px;
            border-radius: 10px;
            cursor: pointer;
            position: relative;
            z-index: 10;
        }
    }
    .courses-body {
        margin-top: 20px;
        margin-bottom: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
    .notData {
        text-align: center;
        font-size: 40px;
        padding-bottom: 100px;
    }
    .pagination {
        text-align: center;
        padding-bottom: 30px;
    }
}
#dark .courses-container {
    .courses-header {
        h4 {
            color: #fdfdfd;
        }
        p {
            color: #fdfdfd;
        }
        .courses-filter-icon {
            background-color: #fdfdfd;
        }
    }
    .ant-empty-description{
        color: #fdfdfd !important;
    }
    .courses-body {
        h1 {
            color: #fdfdfd;
        }
    }
}
#light .courses-container {
    .courses-header {
        h4 {
            color: #353535;
        }
        p {
            color: #353535;
        }
        .courses-filter-icon {
            background-color: #353535;
            svg {
                fill: #fdfdfd;
            }
        }
    }
    .courses-body {
        h1 {
            color: #353535;
        }
    }
}
@media (max-width: 1199px) {
    .courses-container {
        .courses-body{
            grid-template-columns: auto auto;
            justify-content: center;
        }
    }
}
@media (max-width: 869px) {
    .courses-container {
        .courses-header {
            .flex-center {
                justify-content: space-between;
                h4 {
                    font-size: 60px;
                }
                .courses-filter-icon {
                    width: 31px;
                    height: 31px;
                    padding: 5px;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .courses-body{
            grid-template-columns: auto;
        }
    }
}

@media (max-width: 450px) {
    .courses-container {
        .courses-header {
            .flex-center {
                justify-content: space-between;
                h4 {
                    font-size: 32px;
                }
                .courses-filter-icon {
                    width: 31px;
                    height: 31px;
                    padding: 5px;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .courses-body{
            grid-template-columns: 1fr;
        }
    }
}
