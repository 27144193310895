.dropdown {
    padding: 10px 12px 8px 20px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #7dba28;
    color: #667479;
    font-size: 14px;
    cursor: pointer;
    .space {
        display: flex;
        align-items: flex-end !important;
    }
    svg {
        font-size: 12px !important;
    }
}
.menu {
    text-align: center;
    border-radius: 20px !important;
    border: 1px solid #7dba28;
    .menuItem {
        display: flex !important;
        gap: 10px !important;
        align-items: center !important;
        padding: 10px 0 !important;
        color: #667479 !important;
        font-size: 14px !important;
        transition: 0.5s;
        &:hover {
            color: #7dba28 !important;
        }
        img {
            width: 30px !important;
            padding-right: 5px;
        }
    }
}
