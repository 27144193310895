header {
    position: relative;
    .header-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 37px 70px 0 70px;
        padding-top: 37px;
        position: relative;

        // position: fixed;
        // background-color: red;
        // top: 0;
        // width: 100%;

        nav {
            background-color: #fff;
            border-radius: 20px;
            // padding: 10px 20px 8px 20px;
            // position: absolute;
            // left: 25%;
            border: 1px solid #7dba28;
            overflow: hidden;
            ul {
                display: flex;
                // gap: 10px;
                text-decoration: none;
                a {
                    list-style: none;
                    text-decoration: none;
                }
                li {
                    list-style: none;
                    color: #667479;
                    font-size: 14px;
                    transition: 0.5s;
                    cursor: pointer;
                    padding: 10px 15px;
                    border-radius: 20px;
                    &:hover {
                        color: #fff;
                        background-color: #7dba28;
                        // text-decoration: underline;
                    }
                    // &:nth-child(1) {
                    //     &::after {
                    //         content: "|";
                    //         margin-left: 10px;
                    //         color: #7dba28;
                    //     }
                    // }
                    // &:nth-child(2) {
                    //     &::after {
                    //         content: "|";
                    //         margin-left: 10px;
                    //         color: #7dba28;
                    //     }
                    // }
                    // &:nth-child(3) {
                    //     &::after {
                    //         content: "|";
                    //         margin-left: 10px;
                    //         color: #7dba28;
                    //     }
                    // }
                }
            }
        }
        .header-actions {
            display: flex;
            align-items: center;
            gap: 25px;
            p {
                font-size: 14px;
                font-weight: 700;
                padding-left: 5px;
                cursor: pointer;
            }
            .mode-sun,
            .mode-moon,
            .search {
                cursor: pointer;
            }
            .location,
            .mode-sun {
                color: #fff;
            }
        }
        .menu-icon {
            display: none;
            .menu-actions{
                display: flex;
            }
        }
    }
    .main-title-itpark {
        border-radius: 18px;
        font-size: 28px;
        font-weight: 400;
        padding: 8px 10px;
        display: inline-flex;
        position: absolute;
        right: 30px;
        top: 180px;
    }
    .main-title {
        width: 1132px;
        margin: 90px auto 0;
        position: relative;
        z-index: 10;
        img {
            width: 100%;
        }
    }
}
#light header {
    .header-nav {
        .header-actions {
            p {
                color: #667479;
            }
            .location {
                color: #667479;
            }
            .mode-moon {
                color: #667479;
            }
        }
    }
    .main-title-itpark {
        border: 1px solid #353535;
        color: #353535;
    }
}
#dark header {
    .header-nav {
        .header-actions {
            p {
                color: #fff;
            }
            .location {
                color: #fff;
            }
        }
        .menu-icon {
            color: #fff;
        }
    }
    .main-title-itpark {
        border: 1px solid #fff;
        color: #fff;
    }
}
@media (max-width: 1132px) {
    header {
        .header-nav {
            padding-left: 10px;
            padding-right: 10px;
            nav {
                display: none;
            }
            .header-actions {
                .item {
                    display: none;
                }
            }
            .menu-icon {
                display: block;
            }
        }
        .main-title {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
@media (max-width: 451px) {
    header {
        .header-nav {
            padding-left: 10px;
            padding-right: 10px;
            nav {
                display: none;
            }
            .header-actions {
                .item {
                    display: none;
                }
            }
            .menu-icon {
                display: block;
            }
        }
        .main-title {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 20px;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
