.card-univer {
    padding: 24px;
    border-radius: 1px;
    position: relative;
    overflow: hidden;
    height: 464px;
    user-select: none;
    h4 {
        font-size: 46px;
        font-weight: 700;
        padding-bottom: 8px;
        position: relative;
        z-index: 10;
    }
    .card-body {
        display: flex;
        gap: 33px;
        margin-top: 25px;
        p {
            font-size: 32px;
            padding-bottom: 14px;
        }
        .pUpper {
            font-size: 28px;
            text-transform: uppercase;
        }
        .card-logo {
            padding-right: 33px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            .card-logo-title {
                font-size: 14px;
                line-height: 20px;
                width: 243px;
            }
            .card-img-box {
                padding: 10px;
                border-radius: 10px;
                position: relative;
                z-index: 50;
                width: 199px;
                height: 122px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: relative;
                z-index: 20;
            }
            .card-btns {
                display: flex;
                align-items: center;
                gap: 20px;
                position: absolute;
                bottom: 10px;
                left: 21px;
                z-index: 40;
                button {
                    padding: 12px 21px;
                    background-color: #7dba28;
                    border-radius: 82px;
                    color: #fff;
                    font-size: 16px;
                    border: none;
                    a {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
        .card-title {
            position: relative;
            z-index: 30;
            display: grid;
            row-gap: 20px;
            .card-rate {
                font-size: 40px;
                margin: 5px 0;
                svg {
                    stroke: #7dba28 !important;
                    stroke-width: 40px;
                }
            }
            button {
                padding: 12px 21px;
                background-color: #7dba28;
                border-radius: 82px;
                color: #fff;
                font-size: 16px;
                border: none;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 10px;
                svg {
                    margin-top: -2px;
                }
            }
            .rate {
                font-size: 15px;
                margin-top: 10px;
            }
            .card-about {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                svg {
                    width: 40px;
                }
                p {
                    padding-bottom: 0;
                }
                span {
                    font-size: 24px;
                    color: #353535;
                }
            }
            button {
                a {
                    color: #fff !important;
                    text-decoration: none !important;
                }
            }
            h5 {
                text-transform: uppercase;
                font-size: 28px;
                font-weight: 400;
                color: #353535;
            }
        }
        .card-background-shadow2 {
            position: absolute;
            bottom: -50px;
            right: 445px;
            height: 490px;
            width: 100px;
            filter: blur(20px);
            border-radius: 40%;
            z-index: 2;
        }
        .card-background-shadow {
            position: absolute;
            bottom: 0;
            right: 330px;
            height: 390px;
            width: 300px;
            opacity: 0.8;
            filter: blur(30px);
            border-radius: 40%;
            z-index: 2;
        }
        .card-background {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 375px;
            width: 500px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // filter: blur(5px);
            }
        }
    }
    .card-body-2 {
        display: flex;
        gap: 33px;
        margin-top: 25px;
        justify-content: flex-end;
        p {
            font-size: 32px;
            padding-bottom: 14px;
            position: relative;
            z-index: 20;
        }
        .pUpper {
            font-size: 28px;
            text-transform: uppercase;
        }
        .card-logo {
            padding-right: 33px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;

            .card-logo-title {
                font-size: 14px;
                line-height: 20px;
                width: 243px;
            }
            .card-img-box {
                padding: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 50;
                width: 199px;
                height: 122px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: relative;
                z-index: 20;
            }
            .card-btns {
                display: flex;
                align-items: center;
                gap: 20px;
                position: absolute;
                bottom: 10px;
                right: 20px;
                z-index: 40;
                button {
                    padding: 12px 21px;
                    background-color: #7dba28;
                    border-radius: 82px;
                    color: #fff;
                    font-size: 16px;
                    border: none;
                    a {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
        .card-title {
            position: relative;
            z-index: 30;
            display: grid;
            row-gap: 20px;
            .card-rate {
                font-size: 40px;
                margin: 5px 0;
                svg {
                    stroke: #7dba28 !important;
                    stroke-width: 40px;
                }
            }
            button {
                padding: 12px 21px;
                background-color: #7dba28;
                border-radius: 82px;
                color: #fff;
                font-size: 16px;
                border: none;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                svg {
                    margin-top: -2px;
                }
            }
            .rate {
                font-size: 15px;
                margin-top: 10px;
            }
            .card-about {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                svg {
                    width: 40px;
                }
                p {
                    padding-bottom: 0;
                }
                span {
                    font-size: 24px;
                    color: #353535;
                }
            }
            button {
                a {
                    color: #fff !important;
                    text-decoration: none !important;
                }
            }
            h5 {
                text-transform: uppercase;
                font-size: 28px;
                font-weight: 400;
                color: #353535;
            }
        }
        .card-background-shadow2 {
            position: absolute;
            bottom: -50px;
            left: 410px;
            height: 490px;
            width: 100px;
            filter: blur(20px);
            border-radius: 40%;
            z-index: 2;
        }
        .card-background-shadow {
            position: absolute;
            bottom: 0;
            left: 330px;
            height: 390px;
            width: 300px;
            opacity: 0.8;
            filter: blur(30px);
            border-radius: 40%;
            z-index: 2;
        }
        .card-background {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 375px;
            width: 450px;
            overflow: hidden;
            z-index: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // filter: blur(5px);
            }
        }
    }
}

#dark .card-univer {
    border: 1px solid #fff;
    background-color: #1d1d1d;
    h4 {
        color: #ebeeef;
        border-bottom: 1px solid #696767c1;
    }
    .card-body {
        p {
            color: #fdfdfd;
        }
        span {
            color: #fdfdfd;
        }
        h5 {
            color: #fdfdfd;
        }
        .card-logo {
            border-right: 1px solid #696767c1;
            .card-img-box {
                background-color: transparent;
            }
        }
        .card-background-shadow2 {
            background-color: #1d1d1d;
        }
        .card-background-shadow {
            background-color: #1d1d1d;
        }
    }
    .card-body-2 {
        p {
            color: #fdfdfd;
        }
        span {
            color: #fdfdfd;
        }
        h5 {
            color: #fdfdfd;
        }
        .card-logo {
            border-right: 1px solid #696767c1;
            .card-img-box {
                background-color: transparent;
            }
        }
        .card-background-shadow2 {
            background-color: #1d1d1d;
        }
        .card-background-shadow {
            background-color: #1d1d1d;
        }
    }
}
#light .card-univer {
    border: 1px solid #353535;
    background-color: #f2f2f2;
    h4 {
        color: #353535;
        border-bottom: 1px solid #353535;
    }
    .card-body {
        p {
            color: #353535;
        }
        .card-logo {
            border-right: 1px solid #353535;
            .card-img-box {
                background-color: #a19e9e;
            }
        }
        .card-background-shadow2 {
            background-color: #f2f2f2;
        }
        .card-background-shadow {
            background-color: #f2f2f2;
        }
    }
    .card-body-2 {
        p {
            color: #353535;
        }
        .card-logo {
            border-right: 1px solid #353535;
            .card-img-box {
                background-color: #a19e9e;
            }
        }
        .card-background-shadow2 {
            background-color: #f2f2f2;
        }
        .card-background-shadow {
            background-color: #f2f2f2;
        }
    }
}
@media (max-width: 1100px) {
    .card-univer {
        .card-body {
            .card-background {
                img {
                    opacity: 0.3;
                }
            }
        }
        .card-body-2 {
            .card-background {
                img {
                    opacity: 0.3;
                }
            }
        }
    }
}
@media (max-width: 820px) {
    .card-univer {
        height: 380px;
        h4 {
            font-size: 32px;
        }
        .card-body {
            .card-title {
                align-items: flex-end;
                .card-about {
                    div {
                        p {
                            font-size: 28px;
                        }
                        span {
                            font-size: 16px;
                        }
                    }
                }
                .flex-center-gap {
                    button {
                        padding: 4px 10px !important;
                        font-size: 14px !important;
                        svg {
                            width: 16px;
                        }
                    }
                }
            }
            .card-background {
                height: 305px;
                // width: 407px;
            }
            .card-btns {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 10px !important;
                left: 10px !important;
                z-index: 50 !important;
                button {
                    padding: 8px 10px !important;
                    font-size: 14px !important;
                }
            }
        }
        .card-body-2 {
            .card-title {
                align-items: flex-end;
                .card-about {
                    div {
                        p {
                            font-size: 28px;
                        }
                        span {
                            font-size: 16px;
                        }
                    }
                }
                .flex-center-gap {
                    button {
                        padding: 4px 10px !important;
                        font-size: 14px !important;
                        svg {
                            width: 16px;
                        }
                    }
                }
            }
            .card-background {
                height: 305px;
                // width: 407px;
            }
            .card-btns {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 10px !important;
                left: 10px !important;
                z-index: 50 !important;
                button {
                    padding: 8px 10px !important;
                    font-size: 14px !important;
                }
            }
        }
    }
}
@media (max-width: 651px) {
    .card-univer {
        height: 370px;
        h4 {
            font-size: 24px;
        }
        .card-body {
            gap: 20px;
            .card-logo {
                padding-right: 10px;
                .card-img-box {
                    width: 100px;
                    height: 100%;
                    max-height: 100px;
                }
            }
            .card-title {
                .card-about {
                    div {
                        p {
                            font-size: 23px;
                        }
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .card-body-2 {
            gap: 20px;
            .card-logo {
                padding-right: 10px;
                .card-img-box {
                    width: 100px;
                    height: 100%;
                    max-height: 100px;
                }
            }
            .card-title {
                .card-about {
                    div {
                        p {
                            font-size: 23px;
                        }
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
      
    }
}

@media (max-width: 451px) {
    .card-univer {
        height: 300px;
        padding: 10px;
        background-color: #353535 !important;
        h4 {
            font-size: 23px;
            color: #fff !important;
            border-bottom: 1px solid #fff !important;
        }
        .card-body {
            gap: 20px;
            .card-logo {
                padding-right: 10px;
                .card-img-box {
                    width: 100px;
                    height: 100%;
                    max-height: 100px;
                    background-color: transparent !important;
                }
            }
            .card-title {
                align-items: flex-end;
                .card-about {
                    svg {
                        path {
                            stroke: #fff !important;
                        }
                    }
                    div {
                        p {
                            font-size: 20px;
                            color: #fff !important;
                        }
                        span {
                            font-size: 16px;
                            color: #fff !important;
                        }
                    }
                }
                .flex-center-gap {
                    button {
                        padding: 4px 10px !important;
                        font-size: 14px !important;
                        svg {
                            width: 16px;
                        }
                    }
                }
            }
            .card-background {
                height: 299px;
                width: 407px;
                img {
                    filter: blur(1px);
                    opacity: 0.2;
                }
            }
            .card-background-shadow2 {
                display: none;
            }
            .card-background-shadow {
                display: none;
            }
            .card-btns {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 10px !important;
                left: 10px !important;
                z-index: 50 !important;
                button {
                    padding: 8px 10px !important;
                    font-size: 14px !important;
                }
            }
            .card-background {
                img {
                    // display: none;
                }
            }
        }
        .card-body-2 {
            gap: 20px;
            .card-logo {
                padding-right: 10px;
                .card-img-box {
                    width: 100px;
                    height: 100%;
                    max-height: 100px;
                    background-color: transparent !important;
                }
            }
            .card-title {
                align-items: flex-end;
                .card-about {
                    svg {
                        path {
                            stroke: #fff !important;
                        }
                    }
                    div {
                        p {
                            font-size: 20px;
                            color: #fff !important;
                        }
                        span {
                            font-size: 16px;
                            color: #fff !important;
                        }
                    }
                }
                .flex-center-gap {
                    button {
                        padding: 4px 10px !important;
                        font-size: 14px !important;
                        svg {
                            width: 16px;
                        }
                    }
                }
            }
            .card-background {
                height: 299px;
                width: 407px;
                img {
                    filter: blur(1px);
                    opacity: 0.2;
                }
            }
            .card-background-shadow2 {
                display: none;
            }
            .card-background-shadow {
                display: none;
            }
            .card-btns {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 10px !important;
                left: 10px !important;
                z-index: 50 !important;
                button {
                    padding: 8px 10px !important;
                    font-size: 14px !important;
                }
            }
            .card-background {
                img {
                    // display: none;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .card-univer {
        .card-body {
            gap: 0;
            .card-title {
                row-gap: 10px;
                .card-about {
                    div {
                        p {
                            font-size: 18px;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .card-body-2 {
            gap: 10px;
            .card-title {
                row-gap: 10px;
                .card-about {
                    div {
                        p {
                            font-size: 18px;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
