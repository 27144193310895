.menuDrawer {
    width: 100% !important;
    background-color: #1b1b1b !important;
    color: #fff !important;
    .menuActions {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between;
        margin-top: 20px;
        padding: 0 5px;
    }
    .menuNavlink {
        text-align: center;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 400px;
        ul {
            display: flex;
            flex-direction: column;
            gap: 20px;
            li {
                list-style: none;
                font-size: 26px;
                font-weight: 500;
                color: #fff !important;
            }
        }
    }
}
