.card-course {
    width: 380px;
    height: 380px;
    padding: 35px 25px;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
        p {
            opacity: 1;
            transition: 2s !important;
        }
        h4 {
            display: none;
        }
    }
    h4 {
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none !important;
        text-overflow: ellipsis;
        white-space: wrap;

        // overflow: hidden;
        // font-size: 46px;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
        padding-top: 20px;
        text-decoration: none;
        opacity: 0;
    }
    .course-icons {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 0px 25px 20px 25px;
    }
}
#dark .card-course {
    color: #353535;
    border: 2px solid #353535;
}
#light .card-course {
    color: #fff;
    border: 2px solid #ffffff;
}
@media (max-width: 451px) {
    .card-course {
        width: 300px;
        height: 300px;
        padding: 20px 10px 0 10px;
        h4 {
            font-size: 23px !important;
        }
        p {
            font-size: 12px !important;
            line-height: 16px;
            opacity: 1;
        }
        .course-icons {
            padding: 0px 10px 0px 10px;
            svg {
                width: 35px;
            }
        }
    }
}
