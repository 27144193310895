.card-question-container {
    border: 1px solid #5b5b5b;
    display: flex;
    align-items: center;
    border-radius: 1px;
    justify-content: space-between;
    min-height: 125px;
    overflow: hidden;
    width: 100%;
    .card-box {
        .card-question {
            display: flex;
            align-items: center;
            span {
                padding-left: 20px;
            }
            .card-title {
                padding: 20px 0;
                margin-left: 70px;
                h6 {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    max-width: 323px;
                    padding-right: 2px;
                }
                p {
                    font-size: 13px;
                    line-height: 24px;
                    text-decoration: underline;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }
        }
        .card-answer {
            display: flex;
            align-items: flex-start;
            padding: 22px 0;
            // background-color: #7dba28;
            background-color: #fff;
            span {
                color: #fff;
                padding-left: 20px;
            }
            .card-title {
                padding: 0 20px;
                h6 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #000;
                    padding-right: 2px;
                }
            }
        }
        .ant-collapse {
            border: none !important;
            background-color: transparent !important;
            .ant-collapse-item {
                border: none !important;
            }
        }
        .ant-collapse-header {
            display: none !important;
        }
        .ant-collapse-content {
            border-top: none !important;
            transition: 0.5s !important;

            .ant-collapse-content-box {
                padding: 0 !important;
            }
        }
    }
    .card-action {
        border-left: 1px solid #5b5b5b;
        padding: 35px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .card-svg {
            color: #5b5b5b !important;
        }
        .card-svgClose {
            color: #7dba28;
        }
    }
}

#dark .card-question-container {
    .card-box {
        .card-question {
            span {
                color: #fff;
            }
            .card-title {
                h6 {
                    color: #fff;
                }
                p {
                    color: #fff;
                }
            }
        }
    }
}
#light .card-question-container {
    .card-box {
        .card-question {
            span {
                color: #000;
            }
            .card-title {
                h6 {
                    color: #000;
                }
                p {
                    color: #000;
                }
            }
        }
    }
}
@media (max-width: 451px) {
    .card-question-container {
        min-height: 110px;
        .card-box {
            .card-question {
                .card-title {
                    margin-left: 10px;
                    padding: 20px;
                    h6 {
                        font-size: 12px;
                        line-height: 18px;
                        overflow-wrap: break-word;
                    }
                }
            }
            .card-answer {
                .card-title {
                    h6 {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 390px) {
    .card-question-container {
        .card-box {
            .card-question {
                padding: 10px;
                span{
                    font-size: 14px;
                    padding-left: 10px;
                }
                .card-title {
                    margin-left: 10px;
                    padding: 0px;
                    h6 {
                        font-size: 12px;
                        line-height: 18px;
                        overflow-wrap: break-word;
                    }
                }
            }
            .card-answer {
                padding: 10px 0;
                .card-title {
                    h6 {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
        .card-action{
            padding: 20px;
        }
    }
}