h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
  font-weight: 500;
}
p {
  margin-bottom: 0 !important;
}
ol,
ul,
dl {
  margin-bottom: 0 !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-modal .ant-modal-content {
  background-color: transparent;
  box-shadow: none !important;
}
.ant-drawer-content-wrapper {
  height: 100vh !important;
  width: 100vw !important;
  backdrop-filter: blur(10px) !important;
  overflow: hidden !important;
}
#dark .ant-pagination-prev .ant-pagination-item-link {
  color: #fff !important;
}
#dark .ant-pagination-next .ant-pagination-item-link {
  color: #fff !important;
}
#dark .ant-pagination .ant-pagination-item a {
  color: #fff !important;
}
#dark .ant-pagination-item-active a {
  color: #fff !important;
}
#light .ant-pagination-prev .ant-pagination-item-link {
  color: #353535 !important;
}
#light .ant-pagination-next .ant-pagination-item-link {
  color: #353535 !important;
}

#light .ant-pagination-item-active a {
  color: #fff !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 100% !important;
  background: rgba(217, 217, 217, 0.4);
  border: none;
  font-weight: 700 !important;
}
.ant-pagination-item-active {
  background-color: #7dba28 !important;
  border: none !important;
  color: #fff !important;
  a {
    color: #fff !important;
  }
}
.ant-pagination-item {
  border-radius: 100% !important;
  background: rgba(217, 217, 217, 0.4);
  border: none !important;
}

.ant-pagination-options-size-changer.ant-select {
  display: none !important;
}
#light :where(.css-dev-only-do-not-override-mxhywb).ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff !important;
}
#light :where(.css-mxhywb).ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff !important;
}
#dark :where(.css-dev-only-do-not-override-mxhywb).ant-select-single.ant-select-open .ant-select-selection-item {
  color: #353535 !important;
}
#dark :where(.css-mxhywb).ant-select-single.ant-select-open .ant-select-selection-item {
  color: #353535 !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-btn {
  height: 38px !important;
}
:where(.css-mxhywb).ant-btn {
  height: 38px !important;
}
:where(.css-dev-only-do-not-override-mxhywb).ant-btn-primary {
  background-color: #7dba28 !important;
  border: 1px solid #7dba28 !important;
}
:where(.css-mxhywb).ant-btn-primary {
  background-color: #7dba28 !important;
  border: 1px solid #7dba28 !important;
}
