.main-background {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    width: 100%;
    height: 1000px;
    z-index: 0;
    overflow: hidden;
    svg {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 1132px) {
    .main-background {
        width: 100%;
    }
}

