.card-centers {
    padding: 24px;
    border-radius: 1px;
    position: relative;
    overflow: hidden;
    height: 464px;
    user-select: none;
    h4 {
        font-size: 46px;
        font-weight: 700;
        padding-bottom: 8px;
        position: relative;
        z-index: 10;
        cursor: pointer;
    }
    .card-body {
        display: flex;
        gap: 33px;
        margin-top: 25px;
        p {
            font-size: 32px;
            padding-bottom: 14px;
        }
        .pUpper {
            font-size: 20px !important;
            text-transform: uppercase;
        }
        .card-logo {
            padding-right: 33px;
            display: grid;
            align-content: space-between;
            p {
                font-size: 20px !important;
                padding: 0 !important;
            }
            .card-img-box {
                padding: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 50;
                width: 199px;
                height: 122px;
                cursor: pointer;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: relative;
                z-index: 20;
            }
            .card-btns {
                display: flex;
                align-items: center;
                gap: 20px;
                position: absolute;
                bottom: 10px;
                left: 21px;

                button {
                    padding: 12px 21px;
                    background-color: #7dba28;
                    border-radius: 82px;
                    color: #fff;
                    font-size: 16px;
                    border: none;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    // margin-top: 63px;
                    cursor: pointer;
                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
        .card-title {
            position: relative;
            z-index: 30;
            .card-rate-box {
                display: flex;
                align-items: flex-end;
                gap: 10px;
                p {
                    font-size: 28px;
                    font-weight: 700;
                    padding-bottom: 0px;
                }
            }
            .card-rate {
                font-size: 35px;
                margin: 5px 0;
                svg {
                    stroke: #7dba28 !important;
                    stroke-width: 40px;
                }
            }
            button {
                padding: 12px 21px;
                background-color: #7dba28;
                border-radius: 82px;
                color: #fff;
                font-size: 16px;
                border: none;
                display: flex;
                align-items: center;
                gap: 10px;
                svg {
                    margin-top: -2px;
                }
            }
            .rate {
                font-size: 15px;
                margin-top: 10px;
            }
            p {
                display: flex;
                align-items: center;
                gap: 10px;
                svg {
                    width: 40px;
                }
            }
        }
        .card-background-shadow2 {
            position: absolute;
            bottom: -50px;
            right: 445px;
            height: 490px;
            width: 100px;
            filter: blur(20px);
            border-radius: 40%;
            z-index: 2;
        }
        .card-background-shadow {
            position: absolute;
            bottom: 0;
            right: 370px;
            height: 390px;
            width: 300px;
            opacity: 0.8;
            filter: blur(30px);
            border-radius: 40%;
            z-index: 2;
        }

        .card-background {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 375px;
            width: 500px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // filter: blur(5px);
            }
            .card-background-shadow3 {
                position: absolute;
                bottom: 100px;
                right: 0;
                z-index: 2;
                // width: 500px;
                // height: 700px;

                // opacity: 0.8;
                // filter: blur(30px);
                // border-radius: 40%;
            }
        }
    }
    .card-body-2 {
        display: flex;
        gap: 33px;
        margin-top: 25px;
        justify-content: flex-end;
        p {
            font-size: 32px;
            padding-bottom: 14px;
            position: relative;
            z-index: 20;
        }
        .pUpper {
            font-size: 20px;
            text-transform: uppercase;
        }
        .card-logo {
            padding-right: 33px;
            display: grid;
            align-content: space-between;
            p {
                font-size: 20px !important;
                padding: 0 !important;
            }
            .card-img-box {
                padding: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 50;
                width: 199px;
                height: 122px;
                cursor: pointer;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: relative;
                z-index: 20;
            }
            .card-btns {
                display: flex;
                align-items: flex-end;
                gap: 20px;
                position: absolute;
                bottom: 10px;
                right: 20px;
                z-index: 40;
                button {
                    padding: 12px 21px;
                    background-color: #7dba28;
                    border-radius: 82px;
                    color: #fff;
                    font-size: 16px;
                    border: none;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    // margin-top: 63px;
                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
        .card-title {
            position: relative;
            z-index: 30;
            .card-rate-box {
                display: flex;
                align-items: flex-end;
                gap: 10px;
                p {
                    font-size: 28px;
                    font-weight: 700;
                    padding-bottom: 0;
                }
            }
            .card-rate {
                font-size: 35px;
                margin: 5px 0;
                svg {
                    stroke: #7dba28 !important;
                    stroke-width: 40px;
                }
            }
            button {
                padding: 12px 21px;
                background-color: #7dba28;
                border-radius: 82px;
                color: #fff;
                font-size: 16px;
                border: none;
                display: flex;
                align-items: center;
                gap: 10px;
                svg {
                    margin-top: -2px;
                }
            }
            .rate {
                font-size: 15px;
                margin-top: 10px;
            }
            p {
                display: flex;
                align-items: center;
                gap: 10px;
                svg {
                    width: 40px;
                }
            }
        }
        .card-background-shadow2 {
            position: absolute;
            bottom: -50px;
            left: 410px;
            height: 490px;
            width: 100px;
            filter: blur(20px);
            border-radius: 40%;
            z-index: 2;
        }
        .card-background-shadow {
            position: absolute;
            bottom: 0;
            left: 350px;
            height: 390px;
            width: 300px;
            opacity: 0.8;
            filter: blur(30px);
            border-radius: 40%;
            z-index: 2;
        }
        .card-background {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 375px;
            width: 450px;
            overflow: hidden;
            z-index: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // filter: blur(5px);
            }
        }
    }
}

#dark .card-centers {
    border: 1px solid #fff;
    background-color: #1d1d1d;
    h4 {
        color: #ebeeef;
        border-bottom: 1px solid #696767c1;
    }
    .card-body {
        p {
            color: #fdfdfd;
        }
        .card-logo {
            border-right: 1px solid #696767c1;
            .card-img-box {
                background-color: transparent;
            }
        }
        .card-background-shadow2 {
            background-color: #1d1d1d;
        }
        .card-background-shadow {
            background-color: #1d1d1d;
        }
    }
    .card-body-2 {
        p {
            color: #fdfdfd;
        }
        .card-logo {
            border-right: 1px solid #696767c1;
            .card-img-box {
                background-color: transparent;
            }
        }
        .card-background-shadow2 {
            background-color: #1d1d1d;
        }
        .card-background-shadow {
            background-color: #1d1d1d;
        }
    }
}
#light .card-centers {
    border: 1px solid #353535;
    background-color: #f2f2f2;
    h4 {
        color: #353535;
        border-bottom: 1px solid #353535;
    }
    .card-body {
        p {
            color: #353535;
        }
        .card-logo {
            border-right: 1px solid #353535;
            .card-img-box {
                // background-color: #a19e9e;
            }
        }
        .card-background-shadow2 {
            background-color: #f2f2f2;
        }
        .card-background-shadow {
            background-color: #f2f2f2;
        }
    }
    .card-body-2 {
        p {
            color: #353535;
        }
        .card-logo {
            border-right: 1px solid #353535;
            .card-img-box {
                // background-color: #a19e9e;
            }
        }
        .card-background-shadow2 {
            background-color: #f2f2f2;
        }
        .card-background-shadow {
            background-color: #f2f2f2;
        }
    }
}

@media (max-width: 450px) {
    .card-centers {
        height: 100%;
        background-color: #353535 !important;
        h4 {
            font-size: 23px;
            color: #fff !important;
            border-bottom: 1px solid #fff !important;
        }
        .card-body-2 {
            display: grid;
            .card-logo {
                grid-template-columns: auto auto;
                column-gap: 10px;
                border: none !important;
                padding-right: 5px;
                div {
                    p {
                        position: relative;
                        z-index: 50;
                        color: #fff !important;
                    }
                }
                .card-img-box {
                    width: 120px;
                    background-color: transparent !important;
                }
                .card-btns {
                    left: 24px;
                    z-index: 50;
                    gap: 10px;
                    button {
                        padding: 10px 15px;
                        a {
                            font-size: 12px;
                        }
                    }
                }
            }
            .card-title {
                padding-bottom: 30px;
                p {
                    font-size: 23px;
                    color: #fff !important;
                    svg {
                        path {
                            stroke: #fff !important;
                        }
                    }
                }
                .card-rate {
                    font-size: 30px;
                }
                .card-rate-box {
                    p {
                        font-size: 23px;
                    }
                }
                .flex-center-gap {
                    gap: 10px;
                    button {
                        font-size: 12px;
                        padding: 10px 15px;
                        gap: 5px;
                        svg {
                            height: 18px;
                        }
                    }
                }
            }
            .card-background {
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                opacity: 0.2;
                img {
                    filter: blur(1px);
                }
            }
            .card-background-shadow2 {
                display: none;
            }
            .card-background-shadow {
                display: none;
            }
        }
        .card-body {
            display: grid;
            .card-logo {
                grid-template-columns: auto auto;
                column-gap: 10px;
                border: none !important;
                padding-right: 5px;
                div {
                    p {
                        position: relative;
                        z-index: 50;
                        color: #fff !important;
                    }
                }
                .card-img-box {
                    width: 120px;
                    background-color: transparent !important;
                }
                .card-btns {
                    left: 24px;
                    z-index: 50;
                    gap: 10px;
                    button {
                        padding: 10px 15px;
                        a {
                            font-size: 12px;
                        }
                    }
                }
            }
            .card-title {
                padding-bottom: 30px;
                p {
                    font-size: 23px;
                    color: #fff !important;
                    svg {
                        path {
                            stroke: #fff !important;
                        }
                    }
                }
                .card-rate {
                    font-size: 30px;
                }
                .card-rate-box {
                    p {
                        font-size: 23px;
                    }
                }
                .flex-center-gap {
                    gap: 10px;
                    button {
                        font-size: 12px;
                        padding: 10px 15px;
                        gap: 5px;
                        svg {
                            height: 18px;
                        }
                    }
                }
            }
            .card-background {
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                opacity: 0.2;
                img {
                    filter: blur(1px);
                }
            }
            .card-background-shadow2 {
                display: none;
            }
            .card-background-shadow {
                display: none;
            }
        }
    }
}

@media (max-width: 393px) {
    .card-centers {
        height: 550px;
        h4 {
            font-size: 20px;
        }
        .card-body-2 {
            display: grid;
            .card-logo {
                grid-template-columns: auto auto;
                column-gap: 10px;
                border: none !important;
                padding-right: 5px;
                div {
                    p {
                        position: relative;
                        z-index: 50;
                        font-size: 16px !important;
                    }
                }
                .card-img-box {
                    width: 120px;
                }
                .card-btns {
                    left: 24px;
                    z-index: 50;
                    gap: 10px;
                    button {
                        padding: 10px 15px;
                    }
                }
            }
            .card-title {
                p {
                    font-size: 20px;
                }
                .card-rate {
                    font-size: 30px;
                }
                .card-rate-box {
                    p {
                        font-size: 23px;
                    }
                }
                .flex-center-gap {
                    gap: 10px;
                    button {
                        padding: 10px 15px;
                        gap: 5px;
                    }
                }
            }
            .card-background {
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .card-background-shadow2 {
                display: none;
            }
            .card-background-shadow {
                display: none;
            }
        }
        .card-body {
            display: grid;
            .card-logo {
                grid-template-columns: auto auto;
                column-gap: 10px;
                border: none !important;
                padding-right: 5px;
                div {
                    p {
                        position: relative;
                        z-index: 50;
                        font-size: 16px !important;
                    }
                }
                .card-img-box {
                    width: 120px;
                }
                .card-btns {
                    left: 24px;
                    z-index: 50;
                    gap: 10px;
                    button {
                        padding: 10px 15px;
                    }
                }
            }
            .card-title {
                p {
                    font-size: 20px;
                }
                .card-rate {
                    font-size: 30px;
                }
                .card-rate-box {
                    p {
                        font-size: 23px;
                    }
                }
                .flex-center-gap {
                    gap: 10px;
                    button {
                        padding: 10px 15px;
                        gap: 5px;
                        svg {
                            height: 18px;
                        }
                    }
                }
            }
            .card-background {
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                img {
                    opacity: 0.9;
                    filter: blur(5px);
                }
            }
            .card-background-shadow2 {
                display: none;
            }
            .card-background-shadow {
                display: none;
            }
        }
    }
}
