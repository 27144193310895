.questions {
    padding-top: 100px;
    h4 {
        font-size: 46px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }
    .question-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        gap: 20px;
        margin-top: 70px;
        .question-box-left {
            display: grid;
            row-gap: 20px;
        }
        .question-box-right {
            display: grid;
            row-gap: 20px;
        }
    }
    .last-card {
        display: flex;
        justify-content: center;
        width: 580px;
        margin: 20px auto;
    }
}
#dark .questions h4 {
    color: #fff;
}
#light .questions h4 {
    color: #1d1d1d;
}
@media (max-width: 850px) {
    .questions {
        .question-box{
            grid-template-columns: 1fr;
        }
    }
}
@media (max-width: 451px) {
    .questions {
        padding-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        h4 {
            font-size: 23px;
        }
        .question-box{
            grid-template-columns: 1fr;
        }
        .last-card{
            width: 100%;
            margin-top: 20px;
        }
    }
}
