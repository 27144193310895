* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "MazzardH", sans-serif;
    // scroll-behavior: smooth;
}
body {
    background-color: #1b1b1b;
    text-decoration: none;
    list-style: none;
    overflow-x: hidden;
    user-select: none;
}
#light {
    background-color: #ffffff;
}
#dark {
    background-color: #1b1b1b;
}
.wrapper {
    max-width: 1180px;
    margin: 0 auto;
    @media (max-width: 1180px) {
        margin: 0 20px;
    }
    @media (max-width: 451px) {
        // max-width: 450px;
    }
}
.flex-center {
    display: flex;
    align-items: center;
}
.flex-center-gap {
    display: flex;
    align-items: center;
    gap: 20px;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track-y {
    background-color: transparent;
    border-radius: 50px;
}
::-webkit-scrollbar-thumb {
    background-color: #7dba28;
    border-radius: 50px;
    border: 0 solid transparent;
}
