.centers-filter-component {
    display: flex;
    gap: 20px;
    transition: 1s;
    .selectInput {
        .ant-select-selector {
            font-size: 24px !important;
            height: 45px !important;
            padding-top: 8px !important;
            margin: auto 0 !important;
            border: none !important;
            border-radius: 10px !important;
            &:hover,
            :focus {
                border: none !important;
            }
        }
    }
}
#dark .centers-filter-component .selectInput {
    .ant-select-selector {
        background-color: #fff !important;
        color: #000 !important;
    }
    svg {
        color: #292d32 !important;
        fill: #292d32 !important;
    }
}
#light .centers-filter-component .selectInput {
    .ant-select-selector {
        background-color: #353535 !important;
        color: #fff !important;
    }
    svg {
        color: #fff !important;
        fill: #fff !important;
    }
}
@media (max-width: 451px) {
    .centers-filter-component {
        gap: 5px;
        width: 100%;
        .selectInput {
            width: 50% !important;
            .ant-select-selector {
                font-size: 18px !important;
                height: 40px !important;
                padding-top: 5px !important;
                margin: auto 0 !important;
                border: none !important;
                border-radius: 10px !important;
                width: 100% !important;

                &:hover,
                :focus {
                    border: none !important;
                }
            }
        }
    }
}
