footer {
    background-color: #000;
    // border-radius: 50px 50px 0px 0px;
    .footer-container {
        padding-top: 72px;
        .footer-box-top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 30px;
            .footer-box-logo {
                p {
                    margin-top: 40px;
                    width: 213px;
                    a {
                        text-decoration: none;
                        font-size: 14px;
                        color: #fff;
                        line-height: 20px;
                        transition: 0.5s;
                        &:hover {
                            color: #7dba28;
                        }
                    }
                }
            }
            .footer-box-titles {
                display: grid;
                grid-template-columns: auto auto auto;
                column-gap: 40px;
                row-gap: 20px;
                a {
                    text-decoration: none;
                    color: #fff;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28px;
                    transition: 0.5s;

                    &:hover {
                        color: #7dba28;
                    }
                }
            }
            .footer-box-actions {
                display: flex;
                align-items: center;
                gap: 25px;
                #fil {
                    &:hover {
                        path {
                            fill: #7dba28;
                        }
                    }
                    cursor: pointer;
                    path {
                        fill: #fff;
                        transition: 0.5s;
                    }
                }
                #st {
                    cursor: pointer;
                    &:hover {
                        path {
                            stroke: #7dba28;
                        }
                    }
                    path {
                        stroke: #fff;
                        transition: 0.5s;
                    }
                }
            }
        }
        .footer-box-bottom {
            text-align: center;
            padding: 35px 0 51px 0;
            border-top: 1px solid #fff;
            p {
                color: #fff;
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 950px) {
    footer {
        .footer-container {
            padding-left: 20px;
            padding-right: 20px;
            .footer-box-top {
                display: grid;
                grid-template-columns: auto auto auto;
                .footer-box-titles {
                    margin: 40px 0;
                    row-gap: 10px;
                    column-gap: 30px;
                }
                .footer-box-actions {
                    justify-content: center;
                    width: 100%;
                    grid-column: 1 / 4;
                    margin-top: 20px;
                }
            }
        }
    }
}
@media (max-width: 770px) {
    footer {
        .footer-container {
            .footer-box-top {
                .footer-box-titles {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media (max-width: 660px) {
    footer {
        .footer-container {
            padding-left: 20px;
            padding-right: 20px;
            .footer-box-top {
                display: flex;
                flex-direction: column;
                .footer-box-titles {
                    margin: 40px 0;
                    row-gap: 10px;
                    column-gap: 30px;
                    a {
                        font-size: 16px;
                    }
                }
                .footer-box-actions {
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 440px) {
    footer {
        .footer-container {
            .footer-box-top {
                .footer-box-titles {
                    // column-gap: px;
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
@media (max-width: 390px) {
    footer {
        .footer-container {
            .footer-box-top {
                .footer-box-titles {
                    column-gap: 20px;
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
@media (max-width: 350px) {
    footer {
        .footer-container {
            .footer-box-top {
                .footer-box-titles {
                    column-gap: 15px;
                    a {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
