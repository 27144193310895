.our-projects {
    margin-top: 100px;
    h6 {
        font-size: 46px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }
    .our-project-container {
        margin-top: 70px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        h4 {
            font-size: 48px;
            font-weight: 700;
            color: #1d1d1d;
        }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            color: #1d1d1d;
        }
        .our-item {
            border-radius: 1px;
            background-color: #ffffff;
            text-decoration: none;
            .flex-center-gap {
                display: flex;
                align-items: center;
                gap: 25px;
                cursor: pointer;
            }
            &:nth-child(1) {
                height: 390px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                gap: 15px;
                padding: 25px;
                position: relative;
                // img {
                //     width: 298px;
                //     position: absolute;
                //     right: 0;
                //     top: 0;
                // }
                svg {
                    position: absolute;
                    right: 30px;
                    top: 30px;
                }
            }
            &:nth-child(2) {
                height: 390px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                gap: 15px;
                padding: 25px;
                position: relative;
                svg {
                    position: absolute;
                    left: 30px;
                    top: 20px;
                }
                .book {
                    position: absolute;
                    width: 232px;
                    left: 20px;
                    top: 0px;
                    font-size: 46px;
                    font-weight: 700;
                    color: #1d1d1d;
                }
                .education {
                    position: absolute;
                    top: -110px;
                    right: 121px;
                    width: 235px;
                }
            }
        }
    }
}
#dark .our-projects {
    h6 {
        color: #1d1d1d;
    }
    .our-item {
        box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
        color: #1d1d1d;
    }
}
#light .our-projects {
    h6 {
        color: #fff;
    }
    .our-item {
        box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
        color: #1d1d1d;
    }
}
@media (max-width: 980px) {
    .our-projects {
        padding-left: 10px;
        padding-right: 10px;
        .our-project-container {
            .our-item {
                &:nth-child(1) {
                    svg {
                        position: absolute;
                        right: 20px;
                        top: 0px;
                        width: 100px;
                    }
                }
                &:nth-child(2) {
                    svg {
                        position: absolute;
                        left: 20px;
                        top: 0px;
                        width: 100px;
                    }
                }
            }
        }
    }
}
@media (max-width: 770px) {
    .our-projects {
        .our-project-container {
            h4 {
                font-size: 28px !important;
            }
        }
    }
}

@media (max-width: 695px) {
    .our-projects {
        .our-project-container {
            grid-template-columns: 1fr;
            h4 {
                font-size: 24px !important;
            }
            p {
                font-size: 10px;
                line-height: 18px;
            }
            .our-item {
                &:nth-child(1) {
                    height: 239px;
                    svg {
                        position: absolute;
                        right: 20px;
                        top: 0px;
                        width: 100px;
                    }
                }
                &:nth-child(2) {
                    height: 239px;
                    margin-top: 20px;
                    svg {
                        position: absolute;
                        left: 20px;
                        top: 0px;
                        width: 100px;
                    }
                }
            }
        }
    }
}

@media (max-width: 490px) {
    .our-projects {
        h6 {
            font-size: 23px !important;
        }
        .our-project-container {
            .our-item {
                &:nth-child(1) {
                    svg {
                        right: 10px;
                        top: -20px;
                        width: 80px;
                    }
                }
                &:nth-child(2) {
                    svg {
                        left: 10px;
                        top: -10px;
                        width: 80px;
                    }
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .our-projects {
        .our-project-container {
            h4 {
                font-size: 22px !important;
            }
            .our-item {
                &:nth-child(1) {
                    svg {
                        right: 10px;
                        top: -20px;
                    }
                }
                &:nth-child(2) {
                    svg {
                        left: 10px;
                        top: -10px;
                    }
                }
            }
        }
    }
}
@media (max-width: 376px) {
    .our-projects {
        .our-project-container {
            .our-item {
                &:nth-child(1) {
                    padding: 10px;
                    svg {
                        position: absolute;
                        right: 20px;
                        top: -10px;
                    }
                }
                &:nth-child(2) {
                    padding: 10px;
                    svg {
                        position: absolute;
                        left: 20px;
                        top: -10px;
                    }
                }
            }
        }
    }
}
