.map-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    @media (max-width: 1382px) {
        display: grid;
    }
    .map-img {
        width: 763px;
        height: 453.78px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        .mapItem {
            border: none;
            outline: none;
            path {
                transition: 0.7s;
                cursor: pointer;
                // &:hover {
                //     fill: #7dba28 !important;
                // }
            }
        }
        .mapItem1 {
            position: absolute;
            top: 187px;
            left: 178px;
            z-index: 10;
            border-top-right-radius: 100%;
        }
        .mapItem2 {
            position: absolute;
            top: 100px;
            left: 264px;
        }
        .mapItem3 {
            position: absolute;
            top: 225px;
            left: 275px;
        }
        .mapItem4 {
            position: absolute;
            bottom: 21px;
            right: 232px;
            z-index: 10;
            border-radius: 0 0 70px 0;
        }
        .mapItem5 {
            position: absolute;
            bottom: 99px;
            right: 245px;
            z-index: 20;
            border-top-right-radius: 70%;
        }
        .mapItem6 {
            position: absolute;
            bottom: 118px;
            right: 178px;
            z-index: 10;
        }
        .mapItem7 {
            position: absolute;
            bottom: 156px;
            right: 169.5px;
            border-top-right-radius: 100%;
            z-index: 10;
        }
        .mapItem8 {
            position: absolute;
            bottom: 162px;
            right: 93px;
            border-bottom-right-radius: 80%;
            z-index: 1;
        }
        .mapItem9 {
            position: absolute;
            bottom: 195px;
            right: 43px;
            z-index: 10;
        }
        .mapItem10 {
            position: absolute;
            bottom: 189px;
            right: -1px;
            z-index: 20;
        }
        .mapItem11 {
            position: absolute;
            top: -20px;
            left: 0;
            z-index: 1;
        }
        .mapItem12 {
            position: absolute;
            bottom: 145px;
            right: 38px;
            z-index: 10;
        }
        .mapItem13 {
            position: absolute;
            bottom: -15px;
            right: 198px;
            border-top-left-radius: 70%;
        }
        .mapLocationMark {
            font-size: 26px;
            position: absolute;
            cursor: pointer;
        }
        .mapLocationItem1 {
            top: 80px;
            left: 150px;
            z-index: 40;
        }
        .mapLocationItem2 {
            top: 210px;
            left: 190px;
            z-index: 40;
        }
        .mapLocationItem3 {
            bottom: 120px;
            right: 390px;
            z-index: 40;
        }
        .mapLocationItem4 {
            bottom: 280px;
            right: 380px;
            z-index: 40;
        }
        .mapLocationItem5 {
            bottom: 50px;
            right: 300px;
            z-index: 40;
        }
        .mapLocationItem6 {
            bottom: 120px;
            right: 280px;
            z-index: 40;
        }
        .mapLocationItem7 {
            bottom: 150px;
            right: 230px;
            z-index: 40;
        }
        .mapLocationItem8 {
            bottom: 15px;
            right: 230px;
            z-index: 40;
        }
        .mapLocationItem9 {
            bottom: 165px;
            right: 185px;
            z-index: 40;
        }
        .mapLocationItem10 {
            bottom: 205px;
            right: 140px;
            z-index: 40;
        }
        .mapLocationItem11 {
            bottom: 210px;
            right: 50px;
            z-index: 40;
        }
        .mapLocationItem12 {
            bottom: 195px;
            right: 20px;
            z-index: 40;
        }
        .mapLocationItem13 {
            bottom: 170px;
            right: 75px;
            z-index: 40;
        }
    }
    .map-title {
        .map-title-container {
            padding: 40px;
            // border-radius: 30px;
            width: 680px;
            @media (max-width: 1382px) {
            }
            @media (max-width: 1480px) {
                width: 580px;
                margin: 0 auto;
            }
            h4 {
                font-size: 20px;
                font-weight: 400;
                text-align: start;
                padding: 0;
                padding-bottom: 36px;
                transition: 2s;
            }
            .map-title-box {
                display: flex;
                p {
                    font-size: 20px;
                    line-height: 28px;
                    max-width: 400px;
                    min-width: 300px;
                    @media (max-width: 1480px) {
                        font-size: 19px;
                        min-width: 200px;
                    }
                }
                .map-title-box-left {
                    padding-right: 46px;
                    display: grid;
                    row-gap: 30px;
                    @media (max-width: 1480px) {
                        row-gap: 20px;
                    }
                }
                .map-title-box-right {
                    margin-left: 46px;
                    display: grid;
                    row-gap: 30px;
                    p {
                        a {
                            color: #353535;
                            text-decoration: none !important;
                        }
                    }
                }
            }
        }
    }
}
#dark .map-container .map-title .map-title-container {
    border: 1px solid #fff;
    h4 {
        color: #7dba28;
    }
    p {
        color: #fff;
    }
    .map-title-box-left {
        border-right: 1px solid #fff;
    }
    .map-title-box-right {
        p {
            a {
                color: #fff;
            }
        }
    }
}
#light .map-container .map-title .map-title-container {
    border: 1px solid #353535;
    h4 {
        color: #7dba28;
    }
    p {
        color: #353535;
    }
    .map-title-box-left {
        border-right: 1px solid #353535;
    }
    .map-title-box-right {
        p {
            a {
                color: #353535;
            }
        }
    }
}
@media (max-width: 769px) {
    .map-container {
        .map-img {
            display: none;
        }
    }
}
@media (max-width: 645px) {
    .map-container {
        .map-title {
            .map-title-container {
                width: 100%;
                margin: 0 auto;
                padding: 20px;
                h4 {
                    font-size: 16px;
                    padding-bottom: 20px;
                }
                .map-title-box {
                    p {
                        font-size: 16px;
                        line-height: 15px;
                        min-width: 50px;
                        max-width: 450px;
                    }
                    .map-title-box-left {
                        padding-right: 10px;
                        width: 55%;
                    }
                    .map-title-box-right {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}


@media (max-width: 451px) {
    .map-container {
        .map-title {
            .map-title-container {
                width: 100%;
                margin: 0 auto;
                padding: 20px;
                h4 {
                    font-size: 16px;
                    padding-bottom: 20px;
                }
                .map-title-box {
                    p {
                        font-size: 16px;
                        line-height: 15px;
                        min-width: 50px;
                        max-width: 450px;
                    }
                    .map-title-box-left {
                        padding-right: 10px;
                        width: 55%;
                    }
                    .map-title-box-right {
                        margin-left: 10px;
                        p {
                            // &:nth-child(4) {
                            //     font-size: 14px;
                            // }
                            // &:nth-child(5) {
                            //     font-size: 12px;
                            // }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 430px) {
    .map-container {
        .map-img {
            display: none;
        }
        .map-title {
            .map-title-container {
                width: 100%;
                margin: 0 auto;
                padding: 20px;
                h4 {
                    font-size: 16px;
                    padding-bottom: 20px;
                }
                .map-title-box {
                    p {
                        font-size: 14px;
                        line-height: 15px;
                        min-width: 50px;
                        max-width: 450px;
                    }
                    .map-title-box-left {
                        padding-right: 10px;
                        width: 55%;
                    }
                    .map-title-box-right {
                        margin-left: 10px;
                        p {
                            // &:nth-child(4) {
                            //     font-size: 14px;
                            // }
                            // &:nth-child(5) {
                            //     font-size: 12px;
                            // }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 370px) {
    .map-container {
        .map-title {
            .map-title-container {
                h4 {
                    font-size: 14px;
                    padding-bottom: 20px;
                }
                .map-title-box {
                    p {
                        font-size: 12px;
                        line-height: 15px;
                        min-width: 50px;
                        max-width: 450px;
                    }
                    .map-title-box-left {
                        padding-right: 10px;
                        width: 55%;
                        row-gap: 10px;
                    }
                    .map-title-box-right {
                        margin-left: 10px;
                        row-gap: 10px;
                        p {
                            // &:nth-child(4) {
                            //     font-size: 14px;
                            // }
                            // &:nth-child(5) {
                            //     font-size: 12px;
                            // }
                        }
                    }
                }
            }
        }
    }
}

