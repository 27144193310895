.courses-filter-component {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;
    .selectInput {
        .ant-select-selector {
            font-size: 24px !important;
            height: 45px !important;
            padding-top: 8px !important;
            margin: auto 0 !important;
            border: none !important;
            border-radius: 10px !important;
            &:hover,
            :focus {
                border: none !important;
            }
        }
    }
}
#dark .courses-filter-component .selectInput {
    .ant-select-selector {
        background-color: #fff !important;
        color: #000 !important;
    }
    svg {
        color: #292d32 !important;
        fill: #292d32 !important;
    }
}
#light .courses-filter-component .selectInput {
    .ant-select-selector {
        background-color: #353535 !important;
        color: #fff !important;
    }
    svg {
        color: #fff !important;
        fill: #fff !important;
    }
}

@media (max-width: 1015px) {
    .courses-filter-component {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        .selectInput {
            width: 100% !important;
            .ant-select-selector {
                font-size: 16px !important;
                height: 35px !important;
                padding-top: 4px !important;
                width: 100% !important;
            }
            &:nth-child(6) {
                grid-column: 3 / 5;
                grid-row:  1 / 2;
            }
        }
    }
}

@media (max-width: 679px) {
    .courses-filter-component {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 10px;
        .selectInput {
            width: 100% !important;
            .ant-select-selector {
                font-size: 16px !important;
                height: 35px !important;
                padding-top: 4px !important;
                width: 100% !important;
            }
            &:nth-child(1) {
                grid-column: 1 / 3;
            }
            &:nth-child(6) {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }
        }
    }
}
@media (max-width: 410px) {
    .courses-filter-component {
        .selectInput {
            .ant-select-selector {
                font-size: 14px !important;
                height: 35px !important;
                padding-top: 4px !important;
                width: 100% !important;
            }
            &:nth-child(1) {
                grid-column: 1 / 3;
            }
        }
    }
}
