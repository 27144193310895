#dark .home-container {
    .home-box {
        .home-box-left,
        .home-box-right {
            border: 2px solid #fff;
            color: #fff;
            background-color: #1d1d1d;
        }
        .home-box-bottom {
            border: 2px solid #fff;
            border-top: transparent;
            button {
                background-color: #fff;
                color: #1d1d1d;
            }
        }
    }
    .home-map {
        h4 {
            color: #fdfdfd;
        }
        .home-map-container {
            background-color: #1b1b1b;
        }
    }
    .home-our-projects {
        background-color: #fff;
    }
}
#light .home-container {
    .home-box {
        .home-box-left,
        .home-box-right {
            border: 2px solid #353535;
            color: #353535;
            background-color: #fff;
        }
        .home-box-bottom {
            border: 2px solid #353535;
            border-top: transparent;
            button {
                background-color: #353535;
                color: #fff;
            }
        }
    }
    .home-map {
        .home-title {
            color: #1d1d1d;
        }
        .home-map-container {
            background-color: #fff;
        }
    }
    .home-our-projects {
        background-color: #1b1b1b;
    }
}

.home-container {
    margin-top: 40px;
    padding-bottom: 150px;
    .home-box {
        display: flex;
        gap: 20px;
        position: relative;
        margin-bottom: 150px;
        .home-box-left {
            width: 50%;
            height: 489px;
            border-radius: 1px;
            padding: 65px 30px;
            text-decoration: none;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            h4 {
                font-size: 38px;
                font-weight: 700;
                text-align: center;
            }
            .home-main-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -80%);
            }
            .home-icon-box {
                display: flex;
                flex-direction: column;
                gap: 20px;
                p {
                    font-weight: 500;
                    font-size: 42px !important;
                    text-transform: none !important;
                }
            }
        }
        .home-box-right {
            width: 50%;
            height: 489px;
            border-radius: 1px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 65px 30px;
            text-decoration: none;
            text-transform: uppercase;
            position: relative;
            h4 {
                font-size: 38px;
                font-weight: 700;
                text-align: center !important;
            }
            .home-main-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -80%);
            }
            .home-icon-box {
                display: flex;
                flex-direction: column;
                gap: 20px;
                p {
                    font-weight: 500;
                    font-size: 42px !important;
                    text-transform: none !important;
                }
            }
        }
        .home-box-bottom {
            position: absolute;
            bottom: -121px;
            border-radius: 0 0 1px 1px;
            width: 100%;
            height: 180px;
            text-align: center;
            font-family: "StretchPro", sans-serif;
            // @media (max-width: 1180px) {
            //     width: 90%;
            // }
            button {
                padding: 10px 20px 5px;
                border-radius: 20px;
                font-size: 28px;
                font-weight: 400;
                position: relative;
                top: 95px;
                outline: none;
                border: none;
                cursor: pointer;
            }
        }
    }
    .home-map {
        .home-title {
            font-size: 46px;
            font-weight: 700;
            text-align: center;
            padding: 65px 0;
            text-transform: uppercase;
        }
        .home-map-container {
            .home-map-box {
                padding: 50px 0 150px 0;
            }
        }
    }
    .home-our-projects {
        padding: 60px 0;
    }
}
@media (max-width: 906px) {
    .home-container {
        .home-box {
            // display: grid;
            padding-left: 10px;
            padding-right: 10px;
            .home-box-left {
                // height: 277px;
                padding: 35px 20px 10px 20px;
                width: 100%;
                .home-icon-box {
                    // gap: 0px;
                    div {
                        svg {
                            // width: 35px;
                        }
                        p {
                            font-size: 23px !important;
                        }
                    }
                }

                h4 {
                    font-size: 32px;
                }
                .home-main-icon {
                    // top: 60%;
                    svg {
                        // width: 100px;
                    }
                }
            }
            .home-box-right {
                font-size: 23px;
                // height: 277px;
                padding: 35px 20px 10px 20px;
                width: 100%;
                .home-icon-box {
                    // gap: 0px;
                    div {
                        svg {
                            // width: 35px;
                        }
                        p {
                            font-size: 23px !important;
                        }
                    }
                }
                h4 {
                    font-size: 32px;
                }
                .home-main-icon {
                    // top: 60%;
                    svg {
                        // width: 100px;
                    }
                }
            }

            .home-box-bottom {
                border: none !important;
                padding: 0 20px;
                width: 100%;
                button {
                    font-size: 23px;
                }
            }
        }
        // .home-map {
        //     .home-title {
        //         font-size: 23px;
        //         padding-bottom: 0;
        //     }
        //     .home-map-container {
        //         .home-map-box {
        //             padding: 20px;
        //         }
        //     }
        // }
    }
}
@media (max-width: 660px) {
    .home-container {
        .home-box {
            // display: grid;
            .home-box-left {
                height: 377px;
                .home-icon-box {
                    // gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 18px !important;
                        }
                    }
                }

                h4 {
                    font-size: 23px;
                }
                .home-main-icon {
                    // top: 60%;
                    svg {
                        width: 100px;
                    }
                }
            }
            .home-box-right {
                font-size: 18px;
                height: 377px;
                .home-icon-box {
                    // gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 18px !important;
                        }
                    }
                }
                h4 {
                    font-size: 23px;
                }
                .home-main-icon {
                    // top: 60%;
                    svg {
                        width: 100px;
                    }
                }
            }

        }
        .home-map {
            .home-title {
                font-size: 38px;
                padding-bottom: 0;
            }
            .home-map-container {
                .home-map-box {
                    padding: 20px;
                }
            }
        }
    }
}
@media (max-width: 530px) {
    .home-container {
        .home-box {
            display: grid;
            .home-box-left {
                height: 277px;
                .home-icon-box {
                    gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 18px !important;
                        }
                    }
                }

                h4 {
                    font-size: 23px;
                }
                .home-main-icon {
                    top: 60%;
                    svg {
                        width: 100px;
                    }
                }
            }
            .home-box-right {
                font-size: 18px;
                height: 277px;
                .home-icon-box {
                    gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 18px !important;
                        }
                    }
                }
                h4 {
                    font-size: 23px;
                }
                .home-main-icon {
                    top: 60%;
                    svg {
                        width: 100px;
                    }
                }
            }

        }
        .home-map {
            .home-title {
                font-size: 23px;
                padding-bottom: 0;
            }
            .home-map-container {
                .home-map-box {
                    padding: 20px;
                }
            }
        }
    }
}
@media (max-width: 451px) {
    .home-container {
        .home-box {
            display: grid;
            padding-left: 10px;
            padding-right: 10px;
            .home-box-left {
                height: 277px;
                padding: 35px 20px 10px 20px;
                width: 100%;
                .home-icon-box {
                    gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 23px !important;
                        }
                    }
                }

                h4 {
                    font-size: 28px;
                }
                .home-main-icon {
                    top: 60%;
                    svg {
                        width: 100px;
                    }
                }
            }
            .home-box-right {
                font-size: 23px;
                height: 277px;
                padding: 35px 20px 10px 20px;
                width: 100%;
                .home-icon-box {
                    gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 23px !important;
                        }
                    }
                }
                h4 {
                    font-size: 28px;
                }
                .home-main-icon {
                    top: 60%;
                    svg {
                        width: 80px;
                    }
                }
            }

            .home-box-bottom {
                border: none !important;
                button {
                    font-size: 15px;
                }
            }
        }
      
    }
}

@media (max-width: 370px) {
    .home-container {
        .home-box {
            display: grid;
            padding-left: 10px;
            padding-right: 10px;
            .home-box-left {
                height: 277px;
                padding: 35px 20px 10px 20px;
                width: 100%;
                .home-icon-box {
                    gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 20px !important;
                        }
                    }
                }

                h4 {
                    font-size: 24px;
                }
            }
            .home-box-right {
                font-size: 23px;
                height: 277px;
                padding: 35px 20px 10px 20px;
                width: 100%;
                .home-icon-box {
                    gap: 0px;
                    div {
                        svg {
                            width: 35px;
                        }
                        p {
                            font-size: 20px !important;
                        }
                    }
                }
                h4 {
                    font-size: 24px;
                }
            }

        }
    
    }
}
