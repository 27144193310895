.course-wrapper {
    padding: 60px 0;
    text-align: center;
    h6 {
        font-size: 46px;
        font-weight: 700;
        text-transform: uppercase;
    }
    .course-box {
        padding-top: 35px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    .course-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px 25px;
            border: 1px solid #fff;
            border-radius: 30px;
            cursor: pointer;
            font-weight: 700;
        }
    }
}
#light .course-wrapper {
    background-color: #1B1B1B;
    color: #fff;
    .course-header{
        button{
            border: 1px solid #fff;
        }
    }
}
#dark .course-wrapper {
    background-color: #fff;
    color: #353535;
    .course-header{
        button{
            border: 1px solid #353535;
        }
    }
}
@media (max-width:451px) {
    .course-wrapper{
        h6{
            font-size: 25px;
        }
        .course-header button{
            gap: 10px;
            padding: 5px 15px;
        }
    }
}