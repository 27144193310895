.toggle {
    width: 52px;
    height: 26px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4.5px;
    cursor: pointer;
    position: relative;
    .circle {
        background-color: #d9d9d9;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        position: absolute;
        left: 4.5px;
        transition: 1s;
    }
    .moon {
        margin-top: 4px;
        color: #d9d9d9;
    }
    .sun {
        color: #333333;
        margin-top: 4px;
    }
}
#dark .toggle {
    background-color: #333333;
    .circle{
        background-color: #d9d9d9;
    }
}
#light .toggle {
    background-color: #d9d9d9;
    .circle{
        background-color: #333333;
    }
}
