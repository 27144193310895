.card-courses {
    width: 386px;
    border-radius: 20px;
    padding: 20px 25px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        font-family: "Montserrat", sans-serif;
        overflow-wrap: break-word;
    }
    h6 {
        font-size: 28px;
        font-weight: 400;
        margin: 8px 0;
        font-family: "Montserrat", sans-serif;
    }
    p {
        font-size: 15px;
        line-height: 24px;
        font-weight: 700;
        margin-top: 8px;
        font-family: "Montserrat", sans-serif;
    }
    .upCase {
        text-transform: uppercase;
    }
    .card-comp {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        .card-comp-logo {
            width: 80px;
            height: 80px;
            border-radius: 10px;
            padding: 10px;
            // background-color: #fdfdfd;
            background-color: red;
        }
        img {
            object-fit: cover;
            // object-position: center;
            width: 80px;
            height: 80px;
        }
        p {
            font-size: 24px;
            font-family: "Montserrat", sans-serif;
        }
    }
    h3 {
        font-size: 36px;
        margin-top: 10px;
        font-weight: 100;
        font-family: "Montserrat", sans-serif;
    }
    .rate-value {
        font-size: 23px;
        font-family: "Montserrat", sans-serif;
    }
    .card-rate-box {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .card-rate {
        font-size: 20px;
        margin: 5px 0;
        svg {
            stroke: #7dba28 !important;
            stroke-width: 40px;
        }
    }
    .card-buttons {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-top: 10px;
        button {
            padding: 10px 15px;
            background-color: #7dba28;
            font-size: 16px;
            font-family: "Montserrat", sans-serif;
            outline: none;
            border-radius: 82px;
            border: none;
        }
    }
    h5 {
        font-size: 28px;
        font-weight: 400;
        margin-top: 10px;
        font-family: "Montserrat", sans-serif;
    }
    .card-contact {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
            font-size: 15px;
            padding: 10px 10px;
            border: 1px solid #353535;
            border-radius: 82px;
            font-family: "Montserrat", sans-serif;
            font-weight: 400 !important;
            cursor: pointer;
            a {
                text-decoration: none;
            }
        }
    }
    .card-skills {
        margin-top: 10px;
        display: flex;
        div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
        }
        p {
            border-radius: 4px;
            padding: 6px 18px 6px 17px;
            font-weight: 700;
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
            cursor: pointer;
        }
    }
}
#dark .card-courses {
    border: 2px solid #fdfdfd;
    color: #fdfdfd;
    background-color: #1d1d1d;
    button {
        color: #fdfdfd;
    }
    .arrow {
        svg {
            path {
                stroke: #fff;
            }
        }
    }
    .card-contact {
        p {
            border: 2px solid #fff;
            a {
                color: #fff;
            }
        }
    }
    .card-skills {
        p {
            background-color: #282828;
        }
    }
}
#light .card-courses {
    border: 2px solid #353535;
    color: #353535;
    background-color: #fff;
    button {
        color: #fdfdfd;
    }
    .arrow {
        svg {
            path {
                stroke: #353535;
            }
        }
    }
    .card-contact {
        p {
            background-color: #fff;
            a {
                color: #353535;
            }
        }
    }
    .card-skills {
        p {
            background-color: #f5f5f5;
        }
    }
}

@media (max-width: 1199px) {
    .card-courses {
        width: 410px;
    }
}

@media (max-width: 450px) {
    .card-courses {
        width: 380px !important;
        margin: 0 auto;
        padding: 10px 15px;
        line-height: 35px;
        h4 {
            font-size: 32px;
        }
        h3 {
            font-size: 35px;
            overflow-wrap: break-word;
        }
        .card-comp {
            .card-comp-logo {
                width: 60px;
                height: 60px;
            }
            p {
                font-size: 20px;
            }
        }
        .card-rate-box {
        }
        .card-rate {
            font-size: 20px !important;
        }
        .card-buttons {
            button {
                font-size: 14px;
                padding: 0 10px;
            }
        }
        h5 {
            font-size: 23px;
        }
        .card-contact {
            p {
                font-size: 12px;
                padding: 5px 10px;
            }
        }
        .card-skills {
            div {
                p {
                    font-size: 10px;
                }
            }
        }
    }
}

@media (max-width: 410px) {
    .card-courses {
        h4 {
            font-size: 30px;
        }
        h3 {
            font-size: 30px;
        }
    }
}
@media (max-width: 386px) {
    .card-courses {
        width: 300px !important;
        h4 {
            font-size: 28px;
            line-height: 30px;
        }
        .card-buttons {
            button {
                font-size: 14px;
            }
        }
    }
}
